@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/f0b1e3f007006b62-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 400;
font-style: normal;
}

@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/4c5a69d047c2b2dc-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 500;
font-style: medium;
}

@font-face {
font-family: '__centra_486f22';
src: url(/_yc/_next/static/media/f35b5e6220b536eb-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 700;
font-style: bold;
}@font-face {font-family: '__centra_Fallback_486f22';src: local("Arial");ascent-override: 95.65%;descent-override: 28.69%;line-gap-override: 0.00%;size-adjust: 104.55%
}.__className_486f22 {font-family: '__centra_486f22', '__centra_Fallback_486f22'
}.__variable_486f22 {--font-centra: '__centra_486f22', '__centra_Fallback_486f22'
}

